import { defineStore } from 'pinia'

export const useProductListStore = defineStore('productListStore', () => {
  const selectedStock = ref({})

  const setSelectedStock = value => {
    selectedStock.value = value
  }

  return {
    selectedStock,
    setSelectedStock,
  }
})
