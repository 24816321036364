<template>
  <div v-if="reference && selectedStock" :key="reference?.uid">
    <NuxtLinkLocale
      v-show="!isLoading"
      v-custom-attr:class="horizontalCell ? 'horizontalCell product-card' : 'product-card'"
      :exact-active-class="horizontalCell ? 'horizontalCell product-card' : 'product-card'"
      :to="{
        name: 'product-slug',
        params: {
          slug: _reference.slug,
        },
      }"
      :data-test-id="testId + '-ref-link'"
      @click="openProduct()"
    >
      <ImageLazy
        class="product-img"
        :src="reference.thumbnailUrl"
        :width="isSm && smallProductImg ? 100 : 300"
        :max-height="isSm && smallProductImg ? 100 : 300"
        :lazy-loading="lazyImg"
        :background-image="true"
        :data-test-id="testId + '-ref-img'"
        bg-size="contain"
        :bg-min-size="true"
      >
        <div v-if="reference?.brand" class="product-brand">
          <ImageLazy
            v-if="reference?.brand?.logo_url"
            :lazy-loading="lazyImg"
            width="50"
            :src="reference.brand.logo_url"
            :alt="reference.brand.name"
            :title="reference.brand.name"
            :data-test-id="testId + '-ref-brand-logo'"
          />
          <span v-else data-test-id="ref-brand-name">{{ reference.brand.name }}</span>
        </div>
      </ImageLazy>
      <AddToCart
        v-show="!horizontalCell && (isFastAddToCartPage || isSm)"
        ref="fastAddToCart"
        :stock="selectedStock"
        :fast-add-to-cart="true"
        :data-test-id="!horizontalCell && (isFastAddToCartPage || isSm) ? testId + '-ref-add-to-cart' : null"
        :element-test="!horizontalCell && (isFastAddToCartPage || isSm) ? testId : null"
      />
      <div v-if="selectedStock" class="product-txt">
        <div v-if="(!horizontalCell || cartQuantity !== null) && reference.refCo" class="ref-wrapper">
          <RefcoLabel :refco="reference.refCo" class="no-label" :data-test-id="testId + '-ref-refco'" />
        </div>
        <h3 v-if="horizontalCell" class="product-title" :data-test-id="testId + '-ref-title'">
          {{ reference.name }}
        </h3>
        <span v-if="isClub" class="price-label" :data-test-id="testId + '-ref-club'">
          {{ $t('product.exclusiveClub') }}
        </span>
        <StockPrice
          :price="cartQuantity ? selectedStock.storePrice(showVAT) * cartQuantity : selectedStock.storePrice(showVAT)"
          :selling-quantity="$t(selectedStock.packagingLabel)"
          :class-variant="'font-size-md vat-inline'"
          :show-selling-quantity="false"
          :data-test-id="testId + '-ref-price'"
          :element-test="testId"
          :enabled-vat="showVAT ? showVAT : false"
        />
        <div class="product-discount" :data-test-id="testId + '-ref-discount'">
          <span
            v-if="selectedStock.percentageOff() < 0"
            class="price-percent sm"
            :class="{ club: isClub }"
            :data-test-id="testId + '-ref-discount-percent'"
          >
            {{
              selectedStock.percentageOff().toLocaleString(undefined, {
                style: 'percent',
              })
            }}
          </span>
          <span
            v-if="catalogPriceEquivalent > 0 && selectedStock.percentageOff() < 0"
            class="price-catalog sm"
            :data-test-id="testId + '-ref-discount-catalog'"
          >
            {{ localePrice(catalogPriceEquivalent) }}
          </span>
        </div>
        <h3 v-if="!horizontalCell" class="product-title" :data-test-id="testId + '-ref-title'">
          {{ reference.name }}
        </h3>
        <span
          v-if="selectedStock.quantity === reference.publicStocksQuantities && cartQuantity === null"
          class="product-quantity"
          :data-test-id="testId + '-ref-quantity'"
        >
          {{
            selectedStock.isBatch
              ? `${$t('product.addedQuantityBatchNew', {
                  batch: `${selectedStock.batchSize} ${$t(
                    `stock.quantityTypes.${selectedStock.unit}`,
                    selectedStock.batchSize
                  )?.toLowerCase()}`,
                })}`
              : `${selectedStock.quantity} ${
                  $te(`stock.quantityTypes.${selectedStock.unit}`, 0)
                    ? $t(`stock.quantityTypes.${selectedStock.unit}`, selectedStock.quantity)
                    : $t(`stock.quantityTypes.UNIT`, selectedStock.quantity)
                }`
          }}
          <!-- {{ selectedStock.quantity }} {{ $t(`stock.quantityTypes.${selectedStock.unit}`, selectedStock.quantity) }} -->
        </span>
        <span v-else-if="cartQuantity === null" class="product-quantity">
          {{ $t('product.unitsAvailable', _reference.publicStocksQuantities) }}
        </span>
        <ExpiryDateLabel
          v-if="showExpiryDate"
          :stock="selectedStock"
          :plain-text="true"
          :data-test-id="testId + '-ref-expiry-date'"
          class-variant-value="sm border-radius-small bg-grey"
          class-variant-label="u-c-steel-grey"
          class="u-m-t-xs"
        />
      </div>
    </NuxtLinkLocale>
    <AddToCart
      v-show="fastAddToCart && !isSm && horizontalCell"
      ref="fastAddToCart"
      :stock="selectedStock"
      :fast-add-to-cart="true"
      :data-test-id="fastAddToCart && !isSm && horizontalCell ? testId + '-ref-add-to-cart' : null"
      :element-test="fastAddToCart && !isSm && horizontalCell ? testId : null"
      class="referencecell-home"
    />
  </div>
</template>

<script setup>
import RefcoLabel from '@/components/RefcoLabel/RefcoLabel'
import StockPrice from '@/components/Stock/StockPrice/StockPrice'
import ExpiryDateLabel from '@/components/ExpiryDateLabel/ExpiryDateLabel'
import AddToCart from '@/components/Cart/AddToCart/AddToCart'
import { useClubStore } from '@/stores/club'
import { useProductListStore } from '@/stores/productList'

const { isSm } = useDeviceSize()

inject('lazy', true)

const clubStore = useClubStore()
const isClub = computed(() => clubStore.isClub)

const { showVAT, localePrice } = usePrice()

const props = defineProps({
  reference: { type: Object, default: () => {} },
  stock: { type: Object, default: () => {} },
  horizontalCell: { type: Boolean, default: false },
  mode: { type: String, default: '' },
  cartQuantity: { type: Number, default: null },
  showExpiryDate: { type: Boolean, default: false },
  elementNumber: { type: Number, default: null },
  fastAddToCart: { type: Boolean, default: false },
  // Sometimes we need to keep 300 width img in sm
  smallProductImg: { type: Boolean, default: true },
  lazyImg: { type: Boolean, default: true },
  testId: { type: String, default: '' },
})

const isLoading = ref(true)

const _reference = computed(() => {
  if (props.reference) {
    return useReference(props.reference)
  }
  return null
})

const selectedStock = computed(() => {
  if (props.stock?.uid) {
    return useStock(props.stock)
  } else if (_reference.value?.hasStock) {
    const s = useStock({ ..._reference.value?.bestStock, reference: _reference.value })
    return s
  }
  return null
})
const catalogPriceEquivalent = computed(() => {
  const { country } = useCurrentLocale()
  return selectedStock.value?.catalogPriceEquivalent(showVAT, country)
})

const route = useRoute()

const isFastAddToCartPage = computed(() => {
  return (
    route.name.includes('category') ||
    (route.name === 'index' && isClub.value) ||
    route.name === 'merchant-slug' ||
    route.name === 'product-slug' ||
    route.name === 'search-keywords' ||
    route.name === 'brand-brand'
  )
})
onMounted(() => {
  isLoading.value = false
})
const openProduct = () => {
  const sourceId = route.name
  const { analytics, events } = useAnalytics()
  analytics.sendEvent(events.SELECT_ITEM, {
    ecommerce: {
      currency: 'EUR',
      value: Number(_reference.value?.bestStock?.price_vat),
      tax: Number(_reference.value?.bestStock?.price_vat - _reference.value?.bestStock?.price) || 0,
      items: [
        {
          item_id: _reference.value?.uid,
          item_name: _reference.value?.name,
          name: _reference.value?.name,
          currency: 'EUR',
          discount: 0,
          item_brand: _reference.value?.brand?.name,
          ...[...(_reference.value?.categoriesName || [])].reduce((a, b, i) => {
            return { ...a, [`item_category${i === 0 ? '' : i + 1}`]: b }
          }, {}),
          item_category4: 'product',
          price: Number(_reference.value?.bestStock?.price_vat),
          quantity: 1,
          ...(sourceId
            ? {
                item_list_id: sourceId,
                item_list_name: sourceId?.replaceAll('-', ' '),
              }
            : {}),
        },
      ],
    },
  })
  analytics.sendTagEvent(events.TAG_PLAN.MKP.CLICK_PRODUCT, {
    stock_id: _reference.value?.uid,
  })
  useProductListStore().setSelectedStock(
    _reference.value?.hasStock && _reference.value?.bestStock?.uid
      ? {
          stockUid: _reference.value?.bestStock.uid,
          referenceUid: _reference.value?.uid,
          referenceSlug: _reference.value?.slug,
          merchantUid: _reference.value?.bestStock.company_uid,
          merchantSlug: _reference.value?.bestStock.company_slug,
        }
      : null
  )
}
</script>

<style lang="scss">
.product-card {
  display: block;
  overflow: hidden;
  text-decoration: none;

  &:hover {
    .product-title {
      text-decoration: underline;
    }
  }

  @include mq($mq-md) {
    min-width: 150px;
  }

  @include mq($mq-sm) {
    border-radius: 0;
    display: flex;
    flex-direction: column;
    position: relative;
  }

  .product-img {
    background-color: var(--white);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    margin: 0 0 $spacing-sm 0;
    position: relative;
    width: 100%;
    @include aspect-ratio(1, 1);

    @include mq($mq-sm) {
      flex: 0 0 100px;
      margin: $spacing-md 0 0 0;
    }

    .product-brand {
      border-radius: 0 $spacing-sm 0 0;
      position: absolute;
      left: 0;
      bottom: 0;
      padding: 5px;
      background-color: var(--white);
      height: 23px;

      img {
        display: block;
        max-width: 55px;
        max-height: 15px;
        width: 55px;
        height: 15px;
        width: auto;
        height: auto;
      }

      span {
        color: var(--night-blue);
        text-transform: uppercase;
        font-size: pxToRem(9px);
        font-weight: 800;
      }
    }
  }

  .product-txt {
    padding: $spacing-xs 0;

    @include mq($mq-sm) {
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;
    }

    .ref-wrapper {
      height: 20px;
      display: flex;
      .product-refco {
        background-color: var(--steel-grey);
        border-radius: $spacing-xxs;
        padding: $spacing-xxxs $spacing-xs;
        &-label,
        &-value {
          color: var(--white);
        }
      }
    }

    .product-title {
      margin-top: $spacing-xs;
      margin-bottom: $spacing-xs;
      font-size: pxToRem(15px);
      line-height: 1.4;
      font-weight: 400;
      min-height: 65px;
      word-break: break-word;
      @include line-clamp(3);

      @include mq($mq-sm) {
        font-size: pxToRem(13px);
        line-height: 1.3;
        min-height: 0;
        flex-grow: 1;
        min-height: 65px;
      }
    }

    .product-discount {
      align-items: center;
      display: flex;
      height: 20px;
      margin: $spacing-sm 0 0 0;

      .price-catalog {
        flex: 1 1 auto;
        font-size: pxToRem(12px);
        font-weight: 350;
        color: var(--dark-blue);
      }

      .price-percent {
        background-color: var(--yellow);
        color: var(--dark-blue);
        border-radius: $spacing-xxxs;
        font-weight: 350;
        margin-right: $spacing-sm;

        &.club {
          background-color: var(--club-secondary);
          color: var(--white);
        }
      }
    }

    .price-detailed {
      color: var(--dark-blue);

      @include mq($mq-sm) {
        line-height: 1;
      }
    }

    .price-value {
      color: var(--dark-blue);
      font-size: pxToRem(15px);
    }

    .price-details {
      display: flex;
      gap: $spacing-xxs;
      font-size: pxToRem(15px);
      font-weight: 400;
      color: var(--steel-grey);
      span {
        text-transform: none;
        &:first-child {
          opacity: 1;
        }
      }
    }

    .price-label {
      color: var(--steel-grey);
      display: block;
      font-size: pxToRem(10px);
      font-weight: 800;
      height: 7px;
      line-height: 1;
      margin: $spacing-sm 0 $spacing-xs 0;
    }

    .product-quantity {
      color: var(--dark-blue);
      display: block;
      font-size: pxToRem(15px);
      font-weight: 500;
      margin: 4px 0;
      text-transform: lowercase;
      .product-offers-quantity {
        color: var(--steel-grey);
      }
    }
  }

  &.carousel {
    @include mq($mq-md) {
      display: flex;
      position: relative;
    }

    .product-img {
      @include mq($mq-md) {
        margin: $spacing-lg 0 0 $spacing-sm;
        background-position: top center;
        flex: 0 0 100px;
        width: 100px;
      }
    }

    .product-txt {
      @include mq($mq-md) {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
      }

      .ref-wrapper {
        @include mq($mq-md) {
          position: absolute;
          left: $spacing-sm;
          top: $spacing-sm;
        }
      }

      .product-title {
        @include mq($mq-md) {
          font-size: pxToRem(13px);
          line-height: 1.3;
          min-height: 0;
          flex-grow: 1;
          height: 48px;
        }
      }

      .price-detailed {
        @include mq($mq-md) {
          line-height: 1;
        }
      }

      .price-label {
        @include mq($mq-md) {
          margin: 0 0 4px 0;
        }
      }

      .location-delivery-flex {
        .product-location {
          @include mq($mq-md) {
            margin: 0;
            font-size: pxToRem(12px);
          }
        }

        .delivery {
          @include mq($mq-md) {
            width: 28px;
            height: 28px;
            background-size: 20px;
          }
        }
      }
    }
  }

  &.horizontalCell {
    display: flex;
    max-width: 435px;
    min-width: 435px;
    height: 190px;
    min-height: 190px;
    gap: $spacing-sm;
    align-items: center;
    border: 1px solid var(--bg-grey);
    border-radius: 5px;
    padding: $spacing-md;

    .product-img {
      width: 150px;
      height: 150px;
      flex: 0 0 150px;
    }
    @include mq($mq-sm) {
      flex-direction: row;
    }
  }
}
</style>
