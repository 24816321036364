<template>
  <div v-if="stock" class="cart-quantity-selector">
    <NumberStepper
      :default-value="quantity"
      :min-value="1"
      :max-value="stock.buyQuantity"
      :product-stepper="productStepper"
      :element-test="elementTest"
      @on-value-change="value => setQuantity(value)"
    />
  </div>
</template>

<script>
import NumberStepper from '@/components/NumberStepper/NumberStepper'

export default {
  name: 'CartQuantitySelector',
  components: {
    NumberStepper,
  },
  props: {
    stock: { type: Object, default: null },
    startValue: { type: Number, default: 1 },
    productStepper: { type: Boolean, default: true },
    elementTest: { type: String, default: '' },
  },
  emits: ['on-cart-quantity-change'],
  data() {
    return {
      quantity: this.startValue,
      outOfStock: false,
    }
  },
  watch: {
    startValue: {
      handler(newValue) {
        this.quantity = newValue
      },
      immediate: true,
    },
  },
  mounted() {
    if (this.stock.buyQuantity === 0) {
      this.quantity = 0
    }
  },
  methods: {
    setQuantity(value) {
      const { analytics, events } = useAnalytics()
      analytics.sendTagEvent(events.TAG_PLAN?.CART?.UPDATE_PRODUCT_QUANTITY, {
        new_unit_amount: value,
      })
      this.quantity = Math.max(1, value)
      this.quantity = Math.min(this.stock.buyQuantity, this.quantity)
      this.onQuantityChange()
    },
    onQuantityChange() {
      this.$emit('on-cart-quantity-change', this.quantity)
    },
  },
}
</script>
